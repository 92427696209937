export const STATUS = {
    NOT_STARTED: 'Not Started',
    ONGOING: 'Ongoing',
    COMPLETED: 'Completed'
}

export const CHAPTER_STATUS = {
    ALL: 'All',
    NOT_STARTED: 'Not Started',
    ONGOING: 'Ongoing',
    COMPLETED: 'Completed',
    OVERDUE: 'Overdue'
}



